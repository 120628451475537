@layer utilities {
    .center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  
    .center-y {
        top: 50%;
        transform: translateY(-50%);
    }
  
    .center-x {
        left: 50%;
        transform: translateX(-50%);
    }
  
    .right-full {
        right: 100%;
    }
  
    .right-none {
        right: auto;
    }
  
    .bottom-full {
        bottom: 100%;
    }
  
    .left-full {
        left: 100%;
    }
  
    .top-half {
        top: 50%;
    }
  
    .top-full {
        top: 100%;
    }
  
    .-left-9999 {
        left: -9999px;
    }
  
    .-right-9999 {
        right: -9999px;
    }
  
    .tooltip-container {
        position: relative;
    }
  }